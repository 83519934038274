import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import ArrowNext from '../../Assets/images/diamond-doves-img/best-seller-img/next-img.png'
import ArrowPerv from '../../Assets/images/diamond-doves-img/best-seller-img/prev-img.png'
import RingBanner from '../../Assets/images/diamond-doves-img/ring-banner.png'
import RingBanner1 from '../../Assets/images/diamond-doves-img/ring-banner1.png'
import BandBanner from '../../Assets/images/diamond-doves-img/band-banner.png'
import BookAnAppointment from '../../Assets/images/diamond-doves-img/book-an-appointment-banner.png'
import { useHistory } from 'react-router-dom'
import axios from "axios";
import { base_url, postHeader } from "../../Helpers/request";
import { toast } from "react-toastify";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Skeleton } from "@mui/material";
import { Await } from "react-router";
const BannerSliderNew = () => {
    
    const bannerSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        lazyLoad: true,
        prevArrow: <Image src={ArrowPerv} alt="Prev Image" className='img-fluid' />,
        nextArrow: <Image src={ArrowNext} alt="Prev Image" className='img-fluid' />,
        speed: 500,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const sliderData = [
        {
            heading: 'Book An Appointment',
            text: 'Discover perfection in the form of jewellery  at Diamond Doves.',
            imgUrl: BookAnAppointment,
            linkUrl: "/make-an-appointment"
        },
        {
            heading: 'Engagement Rings',
            text: 'Unveil a love that shines brighter than the stars with our exquisite collection of engagement rings.',
            imgUrl: RingBanner1,
            linkUrl: "/ringsettings"
        },
        {
            heading: 'Wedding Bands',
            text: 'Unveil a love that shines brighter than the stars with our exquisite collection of Wedding Bands.',
            imgUrl: BandBanner,
            linkUrl: "/jewellery/wedding-rings"
        },

    ]
    const [banner, setBanner] = useState([]);
    const [loading, setLoading] = useState(true);
 const bannerDataApi = async () => {
    setLoading(true);
   await axios
        .get(base_url + `/home/slider`, {
            headers: postHeader,
        })
        .then((response) => {
            if (response.data.status == 1) {
                setBanner(response.data.data);
                localStorage.setItem(
                    "bw-bannerdata",
                    JSON.stringify(response.data.data)
                );
                setLoading(false);
            } else {
                toast.error(response.message, { autoClose: 3000 });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}
    useEffect(() => {
        //-----------------------------------Banner
      bannerDataApi()
    }, []);
    return (
        <>
            <div className='ddov_banner_slider_new'>
                <Container fluid className='p-0'>
                    <Row className='w-100 m-auto'>
                        <Col sm={12} md={12} lg={12} className='m-auto p-0'>
                            {loading ?
                                <div className=' ' style={{ margin: "15px 0px" }}>
                                    <Skeleton variant="text" animation="wave" height={isMobile ? 305 : 700}/>
                                </div> :
                                <Slider {...bannerSettings}>
                                    {banner?.map((val, index) =>
                                      <SliderJs val={val}/>
                                    )}
                                </Slider>}
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    )
}
const SliderJs = ({val}) => {
    const history = useHistory();
    return(
    <div>
    <div className='ddov_home_bg_banner4' style={{ backgroundImage: `url(${isMobile ? val?.mobile_image : val?.image})` }}>
        <div className='ddov_banner_slider_text'>
            <h1 data-aos="fade-up-left" data-aos-duration="700" style={{
                color: '#637D96', textTransform: "capitalize"
            }}>{val?.title}</h1>
            <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300" style={{ textTransform: "capitalize" }} >{val?.short_text}</p>
            <button onClick={() => { history.push(val?.btn_link) }} data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" variant="outline-dark" style={{ textTransform: "capitalize", backgroundColor: "#637D96" }}>{val?.btn_text}</button>
        </div>
    </div>
</div>)
}
export default BannerSliderNew